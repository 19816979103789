import styled from 'styled-components';
import { Section } from '../Layout/styles';
import * as T from '../Typography';

interface IProps {
  overlapBottom?: boolean;
  theme?: any;
}

export const MediaRight = styled(Section)``;

export const Label = styled(T.Label)``;

export const Headline = styled.div`
  margin-top: var(--x1-space);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    /* stylelint-disable */
    && {
      font-size: 5vw;
      line-height: 5.5vw;
    }
    /* stylelint-enable */
  }
`;

export const Strapline = styled.div`
  margin-top: var(--x1-space);
`;

export const Text = styled(T.SmallBodyCopy)`
  color: var(--grey);
  margin-top: var(--x2-space);

  & a {
    color: var(--white);

    &:hover {
      color: var(--yellow);
    }
  }

  & p {
    margin-bottom: var(--x1-space);
  }

  @media (min-width: ${props => props.theme.tabletBreak}) {
    margin-top: var(--x2-space);
  }
`;

export const OffsetWrapper = styled.div`
  margin-top: var(--x4-space);
  display: flex;
  height: 100%;
  position: relative;

  & > * {
    align-self: flex-end;
  }

  /* stylelint-disable */
  ${(props: IProps) =>
    props.overlapBottom &&
    `
    z-index: 1;
    margin-top: 0;
    bottom: calc(var(--x3-space) * -2);

    @media (min-width: ${props.theme.tabletBreak}) {
      bottom: calc(var(--x5-space) * -2);
    }
  `} /* stylelint-enable */
`;
