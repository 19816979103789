import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import MediaRight from '../components/MediaRight';
import Media from '../components/Media';
import BoxImage from '../components/BoxImage';
import Background from '../components/Background';
import Head from '../components/Head';

const PortfolioPage = ({ data }) => {
  const portfolio = data.portfolio.frontmatter;
  return (
    <Layout>
      <Background />
      <Head title={portfolio.title} description={portfolio.description} />

      <Header {...portfolio.header} />
      {portfolio.portfolio &&
        portfolio.portfolio.map((item, i) => {
          if (i % 2 === 0) {
            return <MediaRight key={item.headline} {...item} />;
          } else {
            return <Media key={item.headline} {...item} />;
          }
        })}
      <BoxImage {...portfolio.boxImage2} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query SitePortfolioPageQuery {
    portfolio: markdownRemark(
      frontmatter: { templateKey: { eq: "portfolio" } }
    ) {
      frontmatter {
        title
        description
        header {
          headline
          straplines
          address
        }
        portfolio {
          image {
            childImageSharp {
              fluid(quality: 85, maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          label
          headline
          strapline
          text
        }

        boxImage2 {
          boxes2 {
            image {
              childImageSharp {
                fluid(quality: 85, maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            headline
            button {
              label
              url
            }
          }
        }
      }
    }
  }
`;

export default PortfolioPage;
