import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import RevealingImage from '../RevealingImage';
import * as T from '../Typography';
import * as S from './styles';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  overlapBottom?: boolean;
  image: any;
  label: string;
  color: string;
  headline?: any;
  strapline?: any;
  text?: any;
}

const defaultProps = {
  color: 'var(--white)'
};

const MediaRight = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);

  return (
    <S.MediaRight>
      <Grid columns={24}>
        <Cell width={isMobile ? 22 : 7} left={isMobile ? 2 : 4}>
          {props.label && (
            <S.Label color={props.color} hasLine={true}>
              {props.label}
            </S.Label>
          )}
          {props.headline && (
            <S.Headline as={isMobile ? T.H5 : T.H2}>
              <ReactMarkdown source={props.headline} escapeHtml={false} renderers={{root: 'h2'}} disallowedTypes={['paragraph']} unwrapDisallowed={true} />
            </S.Headline>
          )}
          {props.strapline && (
            <S.Strapline as={isMobile ? T.H5 : T.H3}>
              <ReactMarkdown source={props.strapline} escapeHtml={false} />
            </S.Strapline>
          )}
          {props.text && (
            <S.Text>
              <ReactMarkdown source={props.text} escapeHtml={false} />
            </S.Text>
          )}
        </Cell>
        <Cell width={isMobile ? 22 : 12} left={isMobile ? 6 : 14}>
          <S.OffsetWrapper overlapBottom={props.overlapBottom}>
            <RevealingImage>
              <Img
                backgroundColor="var(--black)"
                className="image"
                fluid={props.image && props.image.childImageSharp.fluid}
              />
            </RevealingImage>
          </S.OffsetWrapper>
        </Cell>
      </Grid>
    </S.MediaRight>
  );
};

MediaRight.defaultProps = defaultProps;

export default withBreakpoints(MediaRight);
